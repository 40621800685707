@import '../../assets/mixins.less';
.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper::after {
  font-family: var(--ag-icon-font-family) !important;
}
.ag-items-flex-content-centered {
  display: flex !important;
  align-items: center !important;
}
.ag-shrink-none {
  flex-shrink: 0 !important;
}
.ag-icon-visibility-none {
  visibility: hidden !important;
}
.ag-content-capitalized {
  text-transform: capitalize !important;
}
.cb-view {
  .ag-items-flex-content-centered();
}
.ag-ltr .ag-header-select-all {
  margin-right: var(--ag-cell-widget-spacing);
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  &.ag-checked::after {
    color: var(--color-checkbox-bg);
  }
}

.ag-theme-alpine {
  --ag-header-background-color: #fff;
  --ag-borders: 0;
  --ag-header-foreground-color: #24292e;
  --ag-row-hover-color: rgba(0, 0, 0, 0.05);
  --ag-odd-row-background-color: transparent;
  --ag-even-row-background-color: transparent;
  --ag-selected-row-background-color: transparent;
  --color-link: #24292e;
  --ag-checkbox-checked-color: #3e21ee;

  .p-datatable-wrapper {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-radius: 4px;

    .ag-row {
      display: flex;
    }
    .ag-row,
    .ag-header-row {
      border-bottom: 1px solid var(--color-table-row-separator);
    }
    .ag-header-row {
      border-bottom: 1px solid #00152929;
    }
    .ag-cell {
      .ag-items-flex-content-centered();
      font-weight: normal;
      .inline-div {
        display: inline-block;
      }
      a.ag-title-column {
        &:hover {
          color: var(--color-primary-light);

          [class*='text-lb-'] {
            color: var(--color-primary-light);
          }
        }
        &.is-external-link {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          color: var(--color-table-text);
          .icon-external {
            display: none;
          }
          &:hover {
            color: var(--color-primary-light);

            [class*='text-lb-'] {
              color: var(--color-primary-light);
            }

            .icon-external {
              .ag-items-flex-content-centered();
            }
          }
        }
      }
      .ag-cell-wrapper {
        max-width: 100%;
      }
    }
    .with-subtext-column {
      .content-subtext-wrapper {
        .content {
          .font-medium(1rem, 1.43rem);
        }
        .subtext {
          .font-medium(0.75rem, 1rem);
          color: --color-text-secondary;
        }
      }
    }
    .ag-header-cell {
      font-weight: 500;
      .ag-header-cell-resize::after {
        background-color: #00152929;
      }
    }
    .ag-cell,
    .ag-header-cell {
      font-size: 1rem;
      line-height: 1.43;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }

    .ag-cell-value-v-centered .ag-cell-value:not(.ag-cell) {
      align-items: center;
    }
    .ag-cell-value:not(.ag-cell) {
      display: flex;
      align-items: start;
    }
    .ag-content-centered {
      .ag-items-flex-content-centered();
    }
    .ag-content-centered {
      &,
      &.ag-header-cell .ag-header-cell-label {
        justify-content: center;
      }
    }

    .ag-content-flex {
      display: flex;
    }

    .ag-content-fit-parent {
      max-width: 100%;
    }
    .ag-table-row-hover-actions {
      visibility: hidden;
    }
    .ag-row:hover {
      .ag-table-row-hover-actions {
        visibility: visible;
        cursor: pointer;
      }
    }
    .ag-row-cb-disabled .ag-checkbox-input-wrapper {
      opacity: 0.6;
      .ag-checkbox-input {
        pointer-events: none;
      }
    }
    .cell-expandable-controls {
      display: flex;
    }
    .item-expanded-details {
      &.email-expanded-details {
        overflow-y: auto;
      }
      border-left: 3px solid #bac4ff;
      width: 100%;
      .font-normal(1rem, 1.43em);
      .field-info {
        display: flex;
        .ui.label.more-items {
          border-radius: 16px;
          font-weight: 1.3em;
        }

        &.attributes,
        &.deleted-folder,
        &.inbox-mails {
          .ui.label {
            .font-normal(0.86rem, 1.34em);
            background: #f1f3ff;
            border-radius: 2px;
            color: var(--color-primary-light);
          }
        }

        &.entities {
          .field-value {
            .font-medium(0.86rem, 1.5em);
            .ag-items-flex-content-centered();
            svg {
              flex-shrink: 0;
            }
            &,
            & a {
              color: var(--color-non-click-text);
            }
          }
        }

        &.attachments {
          .field-value {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            & > span {
              .ag-items-flex-content-centered();
              max-width: 15%;
              svg {
                flex-shrink: 0;
              }
              a,
              span {
                .font-normal(0.86rem, 1.5em);
              }
            }
          }
        }

        &.alerts {
          .field-value {
            div {
              span {
                align-items: flex-start;
                display: inline-flex;
                .font-medium(0.86rem, 1.5em);
                .icon-box {
                  padding-top: 0;
                }
              }
            }
          }
        }

        label {
          // .font-normal(0.86rem, 1.5em);
          // color: var(--color-river-bed);
          min-width: 12rem;
        }

        & + .field-info {
          margin-top: 1.7rem;
        }
      }
    }
  }
}

.lb-table {
  .lb-table-wrapper {
    .table-container {
      .p-datatable {
        width: 100%;
      }
    }
  }
  .p-datatable-tbody {
    .lb-table-first-col-body.p-frozen-column.p-align-left {
      z-index: 1;
    }
    a.ag-title-column {
      &:hover {
        color: var(--color-primary-light);

        [class*='text-lb-'] {
          color: var(--color-primary-light);
        }
      }
      &.is-external-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        color: var(--color-table-text);
        .icon-external {
          visibility: hidden;
        }
        &:hover {
          color: var(--color-primary-light);

          [class*='text-lb-'] {
            color: var(--color-primary-light);
          }

          .icon-external {
            visibility: visible;
            .ag-items-flex-content-centered();
          }
        }
      }
    }

    .lb-table-first-col-body-container {
      .ag-items-flex-content-centered();
      font-weight: normal;
      .inline-div {
        display: inline-block;
      }

      .ag-cell-wrapper {
        max-width: 100%;
      }
    }

    .p-datatable-row-expansion {
      td {
        padding: 0 !important;
      }
    }

    .item-expanded-details {
      &.email-expanded-details {
        overflow-y: auto;
      }
      border-left: 3px solid #bac4ff;
      width: 100%;
      .font-normal(1rem, 1.43em);
      .field-info {
        display: flex;
        .ui.label.more-items {
          border-radius: 16px;
          font-weight: 1.3em;
        }

        &.attributes,
        &.deleted-folder,
        &.inbox-mails {
          .ui.label {
            .font-normal(0.86rem, 1.34em);
            background: #f1f3ff;
            border-radius: 2px;
            color: var(--color-primary-light);
          }
        }

        &.entities {
          .field-value {
            .font-medium(0.86rem, 1.5em);
            .ag-items-flex-content-centered();
            svg {
              flex-shrink: 0;
            }
            &,
            & a {
              color: var(--color-non-click-text);
            }
          }
        }

        &.attachments {
          .field-value {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            & > span {
              .ag-items-flex-content-centered();
              max-width: 15%;
              svg {
                flex-shrink: 0;
              }
              a,
              span {
                .font-normal(0.86rem, 1.5em);
              }
            }
          }
        }

        &.alerts {
          .field-value {
            div {
              span {
                align-items: flex-start;
                display: inline-flex;
                .font-medium(0.86rem, 1.5em);
                .icon-box {
                  padding-top: 0;
                }
              }
            }
          }
        }

        label {
          // .font-normal(0.86rem, 1.5em);
          // color: var(--color-river-bed);
          min-width: 12rem;
        }

        & + .field-info {
          margin-top: 1.7rem;
        }
      }
    }
  }
}
